import { useLoaderData } from '@remix-run/react';

import { EnumsPageName } from '@lp-lib/api-service-client/public';

import { HomePublicLibraryIndex } from '../../app/components/PublicLibrary/HomePublicLibraryIndex';
import { useAppAnalytics } from '../analytics/app/identifiable';
import { PublicHomeLayoutV2 } from '../components/PublicHome/PublicHomeLayout';
import { PublicHomeProvider } from '../components/PublicHome/PublicHomeProvider';
import { apiService } from '../services/api-service';

export async function clientLoader() {
  const pageName = EnumsPageName.PageNameLiveLoggedIn;
  const page = (await apiService.page.get(pageName)).data.page;
  return {
    page,
    pageName,
  };
}

export function shouldRevalidate() {
  return false;
}

function Container() {
  const { page, pageName } = useLoaderData<typeof clientLoader>();

  const analytics = useAppAnalytics();
  return (
    <PublicHomeLayoutV2>
      <HomePublicLibraryIndex
        pageName={pageName}
        page={page}
        programs={[]}
        featuredItems={[]}
        hideCategoriesHeader
        analytics={analytics}
      />
    </PublicHomeLayoutV2>
  );
}

export function Component(): JSX.Element {
  return (
    <PublicHomeProvider>
      <Container />
    </PublicHomeProvider>
  );
}
